import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import App from './defaultPages/App';

import * as serviceWorker from './serviceWorker';
import { CometChat } from "@cometchat-pro/chat"
import { COMETCHAT_CONSTANTS } from './consts';

import reducer from './store/reducer';

// import { CometChatUI } from "./cometchat-pro-react-ui-kit/CometChatWorkspace/src";



import './index.scss';

const store = createStore(reducer, compose(
  applyMiddleware(thunk)
));

var appID = COMETCHAT_CONSTANTS.APP_ID;
var region = COMETCHAT_CONSTANTS.REGION;
var authKeyValue = COMETCHAT_CONSTANTS.AUTH_KEY;

var appSetting = new CometChat.AppSettingsBuilder().subscribePresenceForAllUsers().setRegion(region).build();
CometChat.init(appID, appSetting).then(() => {


  const authKey = authKeyValue;
  // const uid = "SUPERHERO7";
  // var name = "Kevin";

const uid = localStorage.getItem("iduser");
var name = localStorage.getItem('alias');
const pushtoken = localStorage.getItem('pushtoken');
const avatar = localStorage.getItem('avatar');



  var user = new CometChat.User(uid);
  user.setName(name);
  user.setAvatar(avatar);
  user.setMetadata({
    userExpoToken: pushtoken
  });
  
  CometChat.createUser(user, authKey).then(
      user => {
          console.log("user created", user);
          CometChat.login(uid, authKey).then(
            user => {
              console.log("Login Successful: 22222", { user }); 
              if(CometChat.setSource) {
                CometChat.setSource("ui-kit", "web", "reactjs");
              }
              console.log("Initialization completed successfully");
              ReactDOM.render(
                <Provider store={store}>
                  <BrowserRouter>
                    <App />
                  </BrowserRouter>
                </Provider>
              , document.getElementById('root'));   
            },
            error => {
              console.log("Login failed with exception:", { error });    
            }
          );
      },error => {
          console.log("error", error);
          CometChat.login(uid, authKey).then(
            user => {
              console.log("Login Successful: 333333", { user }); 
              // this.props.onItemClick(user, CometChat.ACTION_TYPE.TYPE_USER);

              if(CometChat.setSource) {
                CometChat.setSource("ui-kit", "web", "reactjs");
              }
              console.log("Initialization completed successfully");
              ReactDOM.render(
                <Provider store={store}>
                  <BrowserRouter>
                    <App />
                  </BrowserRouter>
                </Provider>
              , document.getElementById('root'));   
            },
            error => {
              console.log("Login failed with exception:", { error });    
            }
          );
      }
  )

  // CometChat.login(uid, authKey).then(
  //   user => {
  //     console.log("Login Successful:", { user });    
  //   },
  //   error => {
  //     console.log("Login failed with exception:", { error });    
  //   }
  // );



    // if(CometChat.setSource) {
    //   CometChat.setSource("ui-kit", "web", "reactjs");
    // }
    // console.log("Initialization completed successfully");
    // ReactDOM.render(
    //   <Provider store={store}>
    //     <BrowserRouter>
    //       <App />
    //     </BrowserRouter>
    //   </Provider>
    // , document.getElementById('root'));
  },
  error => {
    console.log("Initialization failed with error:", error);
    // Check the reason for error and take appropriate action.
  }
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
