import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { createBrowserHistory } from 'history';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';

import PrivateRoute from '../../PrivateRoute';

import KitchenSinkApp from '../../defaultPages/KitchenSinkApp';
import HomePage from '../../defaultPages/HomePage';

import * as actions from '../../store/action';

import {
    CometChatUI,
    CometChatConversationList,
    CometChatConversationListWithMessages,
    CometChatUserList,
    CometChatUserListWithMessages,
    CometChatGroupList,
    CometChatGroupListWithMessages,
    CometChatMessages
} from '../../cometchat-pro-react-ui-kit/CometChatWorkspace/src';

import {
    wrapperStyle
} from "./style";

console.log('disparo evento saladechat - src/defaultPages/App/index.js')
if(window.ReactNativeWebView){
    window.ReactNativeWebView.postMessage('saladechat@@Chat');
}

const history = createBrowserHistory();
var params = new URLSearchParams(window.location.search);
const uid = params.get('iduser');
localStorage.setItem("iduser", params.get('iduser'));
const name = params.get('alias');
localStorage.setItem("alias", name);
const pushtoken = params.get('pushtoken');
localStorage.setItem("pushtoken", pushtoken);
const avatar = params.get('avatar');
localStorage.setItem("avatar", avatar);

var chatprofile = params.get('chatprofile');
var idtosend = params.get('idtosend');
var userExpoTokenToSend = params.get('userExpoTokenToSend');

class App extends React.Component {
    state = {
        isLoggedin: true
    }

    componentDidMount() {
        this.props.getLoggedinUser();
    }

    render() {
        if(chatprofile === 'yes'){
            console.log('TOken al que hacer push', userExpoTokenToSend)
            localStorage.setItem("pushtokenToSend", userExpoTokenToSend);
            return (
                <>
                    <div css={wrapperStyle()}>
                        <CometChatUI chatWithUser={idtosend} />
                    </div>
                </>
            )
        }else{
            return (
                <div css={wrapperStyle()}>
                    <Router history={history}>
                        <Switch>
                            {/* <PrivateRoute path="/embedded-app" component={CometChatUI} /> */}
                            {/* <Route path="/conversations" component={CometChatConversationListWithMessages} /> */}
                            {/* <PrivateRoute path="/groups" component={CometChatGroupListWithMessages} /> */}
                            {/* <PrivateRoute path="/users" component={CometChatUserListWithMessages} /> */}
                            {/* <PrivateRoute path="/conversation-list" component={CometChatConversationList} /> */}
                            {/* <PrivateRoute path="/group-list" component={CometChatGroupList} /> */}
                            {/* <PrivateRoute path="/user-list" component={CometChatUserList} /> */}
                            {/* <Route path="/messages" component={CometChatMessages} chatWithGroup="supergroup" /> */}
                            {/* <PrivateRoute exact path="/" component={HomePage} /> */}
                            <Route path="/" component={CometChatUI} />
                            {/* <Route path="/" component={HomePage} /> */}
                        </Switch>
                    </Router>
                </div>
            );
        }

    }
}

const mapStateToProps = state => {
    return {
        isLoggedIn: state.isLoggedIn
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getLoggedinUser: () => dispatch(actions.authCheckState())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
